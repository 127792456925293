import { useEffect, useState } from 'react';
import './App.css';

const telegramAPI = window.Telegram.WebApp

function App() {

  const [paramsState, setParamsState] = useState({
    "platform": "",
    "version": ""
  })

  useEffect(() => {
    telegramAPI.ready()

  }, [])

  const onClose = () => {
    telegramAPI.close()
  }
  const onGetInfo = () => {
    setParamsState({
      version: telegramAPI.version,
      platform: telegramAPI.platform
    })
  }
  return (
    <div className="App">
      <button onClick={() => onGetInfo()}>INFO</button>
      <button onClick={() => onClose()}>Close</button>
      <div>Version: "{paramsState.version}"</div>
      <div>Platform: "{paramsState.platform}"</div>
    </div>
  );
}

export default App;
